import * as React from "react"
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { Quote } from "../components/quote/quote";
import { PageSection } from "../components/page-section/page-section";

import "./management.scss";

import image1 from "../images/manage-1-small.png";
import image2 from "../images/manage-2-small.png";
import image3 from "../images/manage-3-small.png";
import image1WP from "../images/manage-1-small.webp";
import image2WP from "../images/manage-2-small.webp";
import image3WP from "../images/manage-3-small.webp";
import largeImage1 from "../images/manage-1.png";
import largeImage2 from "../images/manage-2.png";
import largeImage3 from "../images/manage-3.png";
import largeImage1WP from "../images/manage-1.webp";
import largeImage2WP from "../images/manage-2.webp";
import largeImage3WP from "../images/manage-3.webp";
import { Arrow } from "../components/arrow/arrow";
import FormContainer from "../components/form-container/form-container";

const ManagementPage = () => {
  return (
    <main>
      <SEO
        title="Yacht Management"
        pathname="/management"
        description="Don’t worry, we take care of everything. Project management, Custom management and Full management"
        keywords={["management", "project", "custom", "tailored", "full"]}
      />
      <Layout page="management">
        <div className="my-management">
          <Quote
            text="Don’t worry,"
            highlightedText="we take care of everything."
          />
          <Arrow alignBottom={false} url="full-management" />
          <PageSection id="full-management" image={image1} imageWP={image1WP} largeImage={largeImage1} largeImageWP={largeImage1WP} title="Full management" isReverse={true}>
            <p className="my-management-section">Miami Yachting has created a complete annual yacht management program that covers all.</p>
            <p className="my-management-section">We provide a service and maintenance of your vessel at the same standards of a full-time crew for a much lower cost. You don't need to worry about anything, the only thing you need to do is call us 24 hrs before the time you want to go out and a professional crew will be waiting for you.</p>
            <strong>Our full Yacht Management service include:</strong>
            <ul className="my-management-list">
              <li>Exterior, interior and bottom cleaning.</li>
              <li>Engine room, strainers and bilges cleaning.</li>
              <li>Systems check and maintenance.</li>
              <li>On demand certified crew.</li>
              <li>24/7 Support</li>
              <li>Project management.</li>
              <li>Factory warranty claims.</li>
              <li>Passage planning.</li>
              <li>Catering and Provisioning.</li>
              <li>Documentation.</li>
              <li>Accounting and expenses management.</li>
              <li>Consulting and logistics.</li>
              <li>Safety, maintenance, pre departure and cruising checklists & logbooks.</li>
            </ul>
          </PageSection>
          <Arrow alignBottom={false} url="custom-management" />
          <PageSection id="custom-management" image={image2} imageWP={image2WP} largeImage={largeImage2} largeImageWP={largeImage2WP} title="Tailored management">
            <p className="my-management-section">Choose from our recurring services to keep your boat in great condition and ready for prime time! We will meet with you in person to set up a custom management / maintenance program for your vessel considering your budget, how often you go out and the number of times per month you want us to provide the following services:</p>
            <ul className="my-management-list">
              <li>Wash down.</li>
              <li>Bottom cleaning.</li>
              <li>Interior Cleaning.</li>
              <li>Engine room cleaning.</li>
              <li>Bilges cleaning.</li>
              <li>Compound and wax.</li>
              <li>Metal restoration.</li>
              <li>System check.</li>
              <li>Strainers cleaning.</li>
            </ul>
          </PageSection>
          <Arrow alignBottom={false} url="project-management" />
          <PageSection id="project-management" image={image3} imageWP={image3WP} largeImage={largeImage3} largeImageWP={largeImage3WP} title="Project management" hasMargin={false} isReverse={true}>
            <p className="my-management-section">When your boat is hauled out of the water for service or upgrades, our team will be there to oversee all work being performed and do our best to save money in as many areas as we can without cutting corners. There is a lot involved with getting your boat to the yard on time then back to your slip before the time that you have planned your vacation.</p>
            <p className="my-management-section">This is often a stressful operation and we make sure that yacht management is as easy as possible. On each project we will:</p>
            <ul className="my-management-list">
              <li>Define the scope of work, owners/shipyards requirements and project goals.</li>
              <li>Identify work required to achieve yacht goals.</li>
              <li>Prepare timelines of key construction milestones.</li>
              <li>Responsible of having a team at the yard or on the dock to represent the owner while work is being performed.</li>
              <li>Identify resources required to achieve milestones.</li>
              <li>Track and report project progress.</li>
              <li>Plan and manage resources and budget.</li>
              <li>Manage client communications.</li>
              <li>Manage yacht quality.</li>
              <li>Manage changes to yacht designs and its effect on timeline and budget.</li>
              <li>Ensure project goals are met.</li>
            </ul>
          </PageSection>
          <Arrow url="management-quote" />
          <Quote
            id="management-quote"
            text="Set your sights on"
            highlightedText="clear expense management."
          />
          <FormContainer topic="Management" pagePath="management" />
        </div>
      </Layout>
    </main>
  )
}

export default ManagementPage;
